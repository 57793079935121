<template>
  <div>
    <CRow>
      <CCol md="12">
        <CCard>
          <CCardHeader>
            <strong>Input Field Checks</strong>
          </CCardHeader>
          <CRow>
            <CCol md="8">
              <CCardBody>
                <CForm>
                  <CInput
                    label="Input Check Name *"
                    v-model="inputField.checkName"
                    horizontal
                    :disabled="mode.viewMode"
                    :invalid-feedback="'Please Enter the Name'"
                    @blur="$v.inputField.checkName.$touch()"
                    :is-valid="
                      $v.inputField.checkName.$dirty
                        ? !$v.inputField.checkName.$error
                        : null
                    "
                  >
                  </CInput>
                  <CInputFile
                    label="Image "
                    horizontal
                    type="file"
                    :disabled="mode.viewMode"
                    accept="image/*"
                    @change="uploadImage"
                  />

                  <CRow>
                    <CCol md="3"></CCol>
                    <CCol md="9" align="center">
                      <CCard
                        v-if="inputField.fileId && !$v.inputField.fileId"
                        style="margin-top: 20px"
                      >
                        <CCardBody align="center">
                          <CButton
                            color="danger"
                            style="float: right"
                            @click="removeimage()"
                            :disabled="mode.viewMode"
                          >
                            <CIcon
                              name="cilXCircle"
                              description="Delete"
                            ></CIcon>
                          </CButton>
                          <CImg
                            v-if="inputField.fileId"
                            :src="imageUrl(inputField.fileId)"
                            height="180px"
                            alt="Responsive image"
                          >
                          </CImg>
                        </CCardBody>
                      </CCard>
                    </CCol>
                  </CRow>
                  <!-- </div> -->
                </CForm>
              </CCardBody>
            </CCol>
          </CRow>

          <CCardFooter align="right">
            <CButton
              v-if="mode.viewMode"
              class="mr-2"
              type="submit"
              size="sm"
              color="primary"
              @click="editMode()"
            >
              <CIcon name="cil-pencil" />Edit
            </CButton>

            <CButton
              v-if="!mode.viewMode"
              class="mr-2"
              type="submit"
              size="sm"
              color="success"
              @click="save()"
            >
              <CIcon name="cil-check-circle" /> Save
            </CButton>

            <CButton
              class="mr-2"
              type="Cancel"
              size="sm"
              color="danger"
              @click="cancel()"
            >
              <CIcon name="cil-ban" />Cancel
            </CButton>
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import Vue from "vue";
import MultiSelect from "../../containers/MultiSelect";
import {
  required,
  minLength,
  maxLength,
  url,
  requiredIf,
} from "vuelidate/lib/validators";
export default {
  name: "InputCheck",
  components: {
    MultiSelect,
  },
  data() {
    return {
      mode: {
        newMode: false,
        editMode: false,
        viewMode: false,
      },
      checkId: "",
      inputField: {
        checkId: "0",
        checkName: "",
        fileId: "",
        priority: "0",
        inputFieldId: this.$route.params.inputFieldId,
        mediaType:"0"
      },

      horizontal: { label: "col-3", input: "col-9" },
    };
  },

  validations: {
    inputField: {
      checkName: {
        required,
      },
    },
  },
  created() {
    if (this.$route.params.mode == "new") {
      this.mode.newMode = true;
      this.mode.viewMode = false;
      this.mode.editMode = false;
      this.inputFieldId = this.$route.params.inputFieldId;
    }
    if (this.$route.params.mode == "view") {
      this.mode.viewMode = true;
      this.checkId = this.$route.params.checkId;
      this.inputFieldId = this.$route.params.inputFieldId;
      this.getUser();
    }
    if (this.$route.params.mode == "edit") {
      this.mode.editMode = true;
      this.checkId = this.$route.params.checkId;
      this.inputFieldId = this.$route.params.inputFieldId;
      this.getUser();
    }
  },
  methods: {
    imageUrl(fileId) {
      console.log(fileId);
      return process.env.VUE_APP_API_HOST + "/emapp/file/" + fileId;
    },

    removeimage() {
      this.inputField.fileId = "";
      console.log("input field id:::",this.inputField.fileId)
      this.inputField.mediaType="0";
    },

    uploadImage(files, event) {
      const URL = process.env.VUE_APP_API_HOST + "/emapp/file/upload";
      console.log("uploadImage", URL);
      console.log("files", files);
      console.log("event", event);
      let fdata = new FormData();
      fdata.append("file", files[0]);

      fetch(URL, {
        method: "POST",
        body: fdata,
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("image upload response > ", data);
          this.inputField.fileId = data.data.fileId;
        });
    },
    editMode() {
      this.mode.editMode = true;
      this.mode.viewMode = false;
    },

    cancel() {
      //this.$router.push({ name: 'InputChecks',params: {inputFieldId: this.$route.params.inputFieldId } })
      window.history.back();
    },

    save() {
      this.$v.inputField.$touch();
      if (this.$v.inputField.$invalid) {
        // console.log("form is invalid", JSON.stringify(this.$v.inputField));
      } else {
        this.apiPostSecure(
          process.env.VUE_APP_API_HOST +
            "/emapp/web/secure/save/workPermit/sections/inputCheck",
          this.inputField
        ).then((post) => {
          //this.$router.push({ name: "InputChecks",params: {inputFieldId: this.$route.params.inputFieldId }});
          window.history.back();
        });
      }
    },
    validator(val) {
      return val ? val.length >= 4 : false;
    },

    getUser() {
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST +
          "/emapp/web/secure/workPermit/section/inputCheck/" +
          this.checkId
      )
        .then((response) => response.json())
        .then((data) => (
          
          this.inputField = data,
          console.log(this.inputField)));
    },
  },
  // mounted() {

  // }
};
</script>
